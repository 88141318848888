import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

const CountryNavItem = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ml: 3 }}>
      <img src={t('flag')} alt="Country" width="22px" height="40px" />
    </Box>
  );
};

export default CountryNavItem;
