import React, { useCallback } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import {
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import Remove from '@mui/icons-material/Remove';
import Logout from '@mui/icons-material/Logout';
import { makeStyles } from '@mui/styles';
import { logOut } from '../../../apollo/reactive-variables/localUpdates';
import SubRoutesList from '../../lists/SubRoutesList';

const useStyles = makeStyles(() => ({
  logo: {
    width: 90,
  },
  activeStyle: {
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
  },
}));

const LoggedInMobileNavItems = ({ closeDrawer, routes }) => {
  const client = useApolloClient();
  const classes = useStyles();
  const location = useLocation();

  const handleLogout = useCallback(() => {
    logOut(client);
    closeDrawer();
  }, [client, closeDrawer]);

  const isCollapsed = useCallback(
    (link) => location.pathname.includes(link),
    [location],
  );

  return (
    <>
      <List>
        {routes.map(({ id, label, path, subRoutes }) => (
          <React.Fragment key={label}>
            <ListItemButton
              component={NavLink}
              to={path}
              activeClassName={classes.activeStyle}
              id={`mobile-${id}`}
              onClick={() => subRoutes.length === 0 && closeDrawer()}
            >
              <ListItemIcon sx={{ minWidth: 0, mr: 3 }}>
                <Remove color="fingoWhite" sx={{ transform: 'scale(3, 1)' }} />
              </ListItemIcon>
              <ListItemText
                primary={label}
                primaryTypographyProps={{
                  color: 'white',
                  variant: 'h6',
                }}
              />
            </ListItemButton>
            {subRoutes.length > 0 && (
              <Collapse in={isCollapsed(path)}>
                <SubRoutesList
                  closeDrawer={closeDrawer}
                  subRoutes={subRoutes}
                  routePath={path}
                />
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        mt="auto"
        px={2}
      >
        <IconButton onClick={handleLogout}>
          <Logout color="fingoWhite" fontSize="large" />
        </IconButton>
      </Stack>
    </>
  );
};

LoggedInMobileNavItems.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
      shouldShow: PropTypes.func,
      action: PropTypes.string,
    }),
  ).isRequired,
};

export default LoggedInMobileNavItems;
