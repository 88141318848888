import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { NavbarLink } from '../../links';

const LoggedOutNavItems = ({ title }) => {
  const location = useLocation();
  const isDemo = location.pathname.includes('quick-registration');

  return (
    <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
      <Grid item>
        <NavbarLink
          id="nav-login"
          to="/login"
          title={isDemo ? '' : title}
        />
      </Grid>
    </Grid>
  );
};

LoggedOutNavItems.propTypes = {
  title: PropTypes.string.isRequired,
};

export default LoggedOutNavItems;
