import React from 'react';
import PropTypes from 'prop-types';
import TabNavLink from './TabNavlink';

const Tabs = ({ tabs }) => (
  <>
    {tabs.map(({ id, label, path, quantity }, index) => (
      <TabNavLink
        id={id}
        key={id}
        label={label}
        path={path}
        quantity={quantity}
        index={index}
        optionsCount={tabs.length}
      />
    ))}
  </>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.shape()]).isRequired,
    path: PropTypes.string,
    component: PropTypes.element,
    quantity: PropTypes.number,
  })).isRequired,
};

export default Tabs;
